import React, { Component } from 'react'
import { connect } from 'react-redux'

import banner from '../images/formosa.jpg'
import image1 from '../images/item_sobre-100.jpg'
import image2 from '../images/item_logistica.jpg'
import image3 from '../images/email.jpg'

class Contact extends Component {

	render() {
		const { i18n } = this.props

		return (
			<div>
				<div className="row" style={{ marginBottom: 40 }}>
					<div className="col-sm">
						<div className="position-absolute d-flex justify-content-center align-items-center flex-column"
							style={{ right: 0, left: 0, bottom: 0, top: 0, background: 'hsla(34, 94%, 54%, 0.5)' }}>
							<h1 className="text-center display-3 text-light d-none d-sm-block font-weight-bold ">
								{i18n.contact.banner_title}
							</h1>
							<h1 className="text-center display-5 text-light d-block d-sm-none font-weight-bold">
								{i18n.contact.banner_title}
							</h1>
						</div>
						<div className="w-100 d-none d-sm-block" style={{ background: `url(${banner}) no-repeat center`, minHeight: 400, backgroundSize: 'cover' }} />
						<div className="w-100 d-block d-sm-none" style={{ background: `url(${banner}) no-repeat center`, minHeight: 200, backgroundSize: 'cover' }} />
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm">
							<h5>
								{i18n.contact.title1}
								<hr className="line" />
							</h5>
							<p>
								{i18n.contact.p1}
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md cttemp">
							<div className="row">
								<div className="col-sm-5">
									<img height="200" className="mt-4" src={image1} alt="..." />
								</div>
								<div className="col-sm">
									<h5>
										{i18n.contact.title2}
										<hr className="line" />
									</h5>
									<b>Caliman Agrícola S/A</b><br />
									<p>
										Fazenda Santa Terezinha<br />
										BR 101 - KM 112<br />
										Linhares/ES - Brasil<br />
										<br />
										Fone: <a href="tel:+55 27 2103-1600">+55 (27) 2103-1600</a>
									</p>
								</div>
							</div>
						</div>
						<div className="col-md cttemp">
							<div className="row">
								<div className="col-sm-5">
									<img height="200" className="mt-4" src={image2} alt="..." />
								</div>
								<div className="col-sm">
									<h5>
										{i18n.contact.title3}
										<hr className="line" />
									</h5>
									<b>Caliman Agrícola RN S/A</b><br />
									<p>
										Fazenda Santa Luzia<br />
										Localidade do Tatu<br />
										Pureza/RN - Brasil<br />
										<br />
										Fone: <a href="tel:+55 27 2103-1600">+55 (27) 2103-1600</a>
										{/* <br />
										Fone: <a href="tel:+55 84 99126-0336">+55 (84) 99126-0336</a> */}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm cttemail">
						<div className="acol-sm-5">
							<img className="mt-4" src={image3} alt="..." onClick={() => window.open("mailto:"+atob("cGFwYXlhQGNhbGltYW4uY29tLmJy"))}/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return ({
		i18n: state.lang
	})
}

export default connect(mapStateToProps)(Contact)
