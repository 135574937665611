import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import banner from '../images/banner_home.jpg'
import about from '../images/item_sobre-100.jpg'
import product from '../images/item_sobre-copy-2-100.jpg'
import logistics from '../images/item_logistica.jpg'
import social from '../images/item_sobre-copy-100.jpg'

class Home extends Component {

	render() {
		const { i18n } = this.props

		return (
			<div>
				<div className="row" style={{ marginBottom: 40 }}>
					<div className="col-sm">
						<div className="position-absolute d-none d-sm-flex"
							style={{ right: 0, left: 0, bottom: 0, top: 0, background: 'hsla(34, 94%, 54%, 0.5)' }}>
							<div className="container d-flex justify-content-center align-items-end flex-column">
								<h1 className="text-right display-4 mr-4 text-light font-weight-bold" style={{ width: '65%' }}>
									{i18n.home.banner_text}
								</h1>
								<Link to="/produtos" className="btn mr-4 mt-3 btn-light">{i18n.home.btn_more}</Link>
							</div>
						</div>
						<div className="position-absolute d-flex d-sm-none"
							style={{ right: 0, left: 0, bottom: 0, top: 0, background: 'hsla(34, 94%, 54%, 0.5)' }}>
							<div className="container d-flex justify-content-end align-items-end flex-column">
								<h4 className="text-right text-light font-weight-bold pl-4 pr-4" style={{ width: '65%' }}>
									{i18n.home.banner_text}
								</h4>
								<Link to="/produtos" className="btn btn-sm mr-4 mb-4 btn-light">{i18n.home.btn_more}</Link>
							</div>
						</div>
						<div className="w-100 d-none d-sm-block" style={{ background: `url(${banner}) no-repeat center`, minHeight: 400, backgroundSize: 'cover' }} />
						<div className="w-100 d-block d-sm-none" style={{ background: `url(${banner}) no-repeat 35%`, minHeight: 200, backgroundSize: 'cover' }} />
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm">
							<Link to={i18n.menu.link1 || ''} style={{ textDecoration: 'none', color: "#000" }}>
								<img className="d-block img-fluid" src={about} alt="..." />
								<h5>
									{i18n.menu.item1}
									<hr className="line" />
								</h5>
								<p>
									{i18n.home.p1}<br />
								</p>
							</Link>
						</div>
						<div className="col-sm">
							<Link to={i18n.menu.link2 || ''} style={{ textDecoration: 'none', color: "#000" }}>
								<img className="d-block img-fluid" src={product} alt="..." />
								<h5>
									{i18n.menu.item2}
									<hr className="line" />
								</h5>
								<p>
									{i18n.home.p2}<br />
								</p>
							</Link>
						</div>
						<div className="col-sm">
							<Link to={i18n.menu.link3 || ''} style={{ textDecoration: 'none', color: "#000" }}>
								<img className="d-block img-fluid" src={logistics || ''} alt="..." />
								<h5>
									{i18n.menu.item3}
									<hr className="line" />
								</h5>
								<p>
									{i18n.home.p3}<br />
								</p>
							</Link>
						</div>
						<div className="col-sm">
							<Link to={i18n.menu.link5 || ''} style={{ textDecoration: 'none', color: "#000" }}>
								<img className="d-block img-fluid" src={social} alt="..." />
								<h5>
									{i18n.menu.item5}
									<hr className="line" />
								</h5>
								<p>
									{i18n.home.p4}<br />
								</p>
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return ({
		i18n: state.lang
	})
}

export default connect(mapStateToProps)(Home)
