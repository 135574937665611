import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import banner from '../images/DSC_0082MENOR2-1.jpg'
import cert1 from '../images/Fairtrade_Certification_Mark.png'
import cert2 from '../images/globalgap.png'
import prod1 from '../images/Artboard-16-100.jpg'
import prod2 from '../images/Artboard-17-100.jpg'

class About extends Component {

	componentDidMount() {
		document.scrollingElement.scrollTop = 0
	}

	render() {
		const { i18n } = this.props

		return (
			<div>
				<div className="row" style={{ marginBottom: 40 }}>
					<div className="col-sm">
						<div className="position-absolute d-flex justify-content-center align-items-center flex-column"
							style={{ right: 0, left: 0, bottom: 0, top: 0, background: 'hsla(34, 94%, 54%, 0.5)' }}>
							<h1 className="text-center display-3 text-light d-none d-sm-block font-weight-bold ">
								{i18n.about_us.title_banner}
							</h1>
							<h1 className="text-center display-5 text-light d-block d-sm-none font-weight-bold">
								{i18n.about_us.title_banner}
							</h1>
						</div>
						<div className="w-100 d-none d-sm-block" style={{ background: `url(${banner}) no-repeat center`, minHeight: 400, backgroundSize: 'cover' }} />
						<div className="w-100 d-block d-sm-none" style={{ background: `url(${banner}) no-repeat center`, minHeight: 200, backgroundSize: 'cover' }} />
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm">
							<h5>
								{i18n.about_us.title1}
								<hr className="line" />
							</h5>
							<p>
								{i18n.about_us.p1}
							</p>
							<p>
								{i18n.about_us.p2}
							</p>
							<p>
								{i18n.about_us.p3}
							</p>
							<p>
								{i18n.about_us.p4}
							</p>
							<p>
								{i18n.about_us.p5}
							</p>
						</div>
						<div className="col-sm">
							<h5>
								{i18n.about_us.title2}
								<hr className="line" />
							</h5>
							<h6>{i18n.about_us.subtitle1}</h6>
							<p>
								{i18n.about_us.p7}
							</p>
							<h6>{i18n.about_us.subtitle2}</h6>
							<p>
								{i18n.about_us.p8}
							</p>
							<h6>{i18n.about_us.subtitle3}</h6>
							<ul>
								<li>{i18n.about_us.p9}</li>
								<li>{i18n.about_us.p10}</li>
								<li>{i18n.about_us.p11}</li>
							</ul>
						</div>
						<div className="w-100" />
						<div className="col-sm">
							<h5>
								{i18n.menu.item2}
								<hr className="line" />
							</h5>
							<div className="d-flex">
								<img className="d-block mr-3" height="150" src={prod1} alt="..." />
								<img className="d-block" height="150" src={prod2} alt="..." />
							</div>
							<br />
							<Link to="/produtos">{i18n.about_us.link1}</Link>
						</div>
						<div className="col-sm">
							<h5>
								{i18n.menu.item4}
								<hr className="line" />
							</h5>
							<div className="d-flex">
								<div>
									<img className="d-block mr-3" height="150" src={cert1} alt="..." />
								</div>
								<div>
									<img className="d-block" height="150" src={cert2} alt="..." />
									<div class="mt-2 text-center">GGN 4049929509955</div>
								</div>
							</div>
							<br />
							<Link to="/certificados">{i18n.about_us.link2}</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return ({
		i18n: state.lang
	})
}

export default connect(mapStateToProps)(About)
