import React, { Component } from 'react'
import { connect } from 'react-redux'

import banner from '../images/Artboard-26-100-1.jpg'
import cert from '../images/Artboard-27-100.jpg'

class Social extends Component {

	componentDidMount() {
		document.scrollingElement.scrollTop = 0
	}

	render() {
		const { i18n } = this.props

		return (
			<div>
				<div className="row" style={{ marginBottom: 40 }}>
					<div className="col-sm">
						<div className="position-absolute d-flex justify-content-center align-items-center flex-column"
							style={{ right: 0, left: 0, bottom: 0, top: 0, background: 'hsla(34, 94%, 54%, 0.5)' }}>
							<h1 className="text-center display-3 text-light d-none d-sm-block font-weight-bold ">
								{i18n.social.banner_title}
							</h1>
							<h1 className="text-center display-5 text-light d-block d-sm-none font-weight-bold">
								{i18n.social.banner_title}
							</h1>
						</div>
						<div className="w-100 d-none d-sm-block" style={{ background: `url(${banner}) no-repeat center`, minHeight: 400, backgroundSize: 'cover' }} />
						<div className="w-100 d-block d-sm-none" style={{ background: `url(${banner}) no-repeat center`, minHeight: 200, backgroundSize: 'cover' }} />
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm d-flex justify-content-end">
							<img height="150" src={cert} alt="..." />
						</div>
						<div className="col-sm">
							<h5>
								{i18n.social.title1}
								<hr className="line" />
							</h5>
							<p>
								{i18n.social.p1}
							</p>
						</div>
					</div>
					<h5>
						{i18n.social.title2}
						<hr className="line" />
					</h5>
					<div className="row">
						{/* <div className="col-sm">
							<h5>
								{i18n.social.subtitle1}<br />
								<small>{i18n.social.small1}</small>
							</h5>
							<p>
								{i18n.social.p2}
							</p>
						</div> */}
						<div className="col-sm">
							<h5>
								{i18n.social.subtitle2}<br />
								<small>{i18n.social.small2}</small>
							</h5>
							<p>
								{i18n.social.p3}
							</p>
						</div>
						<div className="col-sm">
							<h5>
								{i18n.social.subtitle3}<br />
								<small>{i18n.social.small3}</small>
							</h5>
							<p>
								{i18n.social.p4}
							</p>
						</div>
						<div className="col-sm">
							<h5>
								{i18n.social.subtitle4}<br />
								<small>{i18n.social.small4}</small>
							</h5>
							<p>
								{i18n.social.p5}
							</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return ({
		i18n: state.lang
	})
}

export default connect(mapStateToProps)(Social)
