import React, { Component } from 'react'
import { connect } from 'react-redux'

import banner from '../images/scientist.jpg'
import image1 from '../images/v2.jpg'
import image2 from '../images/v5.jpeg'

class Innovation extends Component {

	render() {
		const { i18n } = this.props

		return (
			<div>
				<div className="row" style={{ marginBottom: 40 }}>
					<div className="col-sm">
						<div className="position-absolute d-flex justify-content-center align-items-center flex-column"
							style={{ right: 0, left: 0, bottom: 0, top: 0, background: 'hsla(34, 94%, 54%, 0.5)' }}>
							<h1 className="text-center display-3 text-light d-none d-sm-block font-weight-bold ">
								{i18n.innovation.banner_title}
							</h1>
							<h1 className="text-center display-5 text-light d-block d-sm-none font-weight-bold">
								{i18n.innovation.banner_title}
							</h1>
						</div>
						<div className="w-100 d-none d-sm-block" style={{ background: `url(${banner}) no-repeat center`, minHeight: 400, backgroundSize: 'cover' }} />
						<div className="w-100 d-block d-sm-none" style={{ background: `url(${banner}) no-repeat center`, minHeight: 200, backgroundSize: 'cover' }} />
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm">
							<h5>
								{i18n.innovation.title1}
								<hr className="line" />
							</h5>
							<p>
								{i18n.innovation.p1}
							</p>
							<p>
								{i18n.innovation.p2}
							</p>
							<p>
								{i18n.innovation.p3}
							</p>
							<p>
								{i18n.innovation.p4}
							</p>
						</div>
						<div className="col-sm">
							<h5>
								{i18n.innovation.title2}
								<hr className="line" />
							</h5>
							<a href={image1} target="_blank" rel="noopener noreferrer">
								<img className="w-50" src={image1} alt="..." />
							</a>
							<a href={image2} target="_blank" rel="noopener noreferrer">
								<img className="w-50" src={image2} alt="..." />
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return ({
		i18n: state.lang
	})
}

export default connect(mapStateToProps)(Innovation)
