import React, { Component } from 'react'
import { connect } from 'react-redux'

import banner from '../images/Artboard-26-100.jpg'
import cert1 from '../images/Fairtrade_Certification_Mark.png'
import cert2 from '../images/globalgap.png'
import cert3 from '../images/tesco1.png'
import cert4 from '../images/grasp1.jpg'
import cert5 from '../images/rainforest.png'

class Certified extends Component {

	render() {
		const { i18n } = this.props

		return (
			<div>
				<div className="row" style={{ marginBottom: 40 }}>
					<div className="col-sm">
						<div className="position-absolute d-flex justify-content-center align-items-center flex-column"
							style={{ right: 0, left: 0, bottom: 0, top: 0, background: 'hsla(34, 94%, 54%, 0.5)' }}>
							<h1 className="text-center display-3 text-light d-none d-sm-block font-weight-bold ">
								{i18n.certificates.banner_title}
							</h1>
							<h1 className="text-center display-5 text-light d-block d-sm-none font-weight-bold">
								{i18n.certificates.banner_title}
							</h1>
						</div>
						<div className="w-100 d-none d-sm-block" style={{ background: `url(${banner}) no-repeat center`, minHeight: 400, backgroundSize: 'cover' }} />
						<div className="w-100 d-block d-sm-none" style={{ background: `url(${banner}) no-repeat center`, minHeight: 200, backgroundSize: 'cover' }} />
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm mb-5">
							<div className="row">
								<div className="align-self-center text-center" style={{ 'min-width': 180 }}>
									<img height="150" src={cert5} alt="..." />
								</div>
								<div className="col-sm">
									<p className="mt-4">
										{i18n.certificates.p5}
									</p>
								</div>
							</div>
						</div>
						<div className="w-100" />
						<div className="col-sm mb-5">
							<div className="row">
								<div className="align-self-center text-center" style={{ 'min-width': 180 }}>
									<img height="150" src={cert1} alt="..." />
								</div>
								<div className="col-sm">
									<p className="mt-4">
										{i18n.certificates.p1}
									</p>
									<p className="mt-4">
										{i18n.certificates.maisinfo}
										: <a href="https://info.fairtrade.net" target="_blank">https://info.fairtrade.net</a>
									</p>
								</div>
							</div>
						</div>
						<div className="w-100" />
						<div className="col-sm mb-5">
							<div className="row">
								<div className="align-self-center text-center" style={{ 'min-width': 180 }}>
									<img height="150" src={cert2} alt="..." />
									<div class="mt-2">GGN 4049929509955</div>
								</div>
								<div className="col-sm">
									<p className="mt-4">
										{i18n.certificates.p2}
									</p>
								</div>
							</div>
						</div>
						{/* <div className="w-100" />
						<div className="col-sm mb-5">
							<div className="row">
								<div className="align-self-center text-center" style={{ 'min-width': 180 }}>
									<img height="150" src={cert3} alt="..." />
								</div>
								<div className="col-sm">
									<p className="mt-4">
										{i18n.certificates.p3}
									</p>
								</div>
							</div>
						</div>
						<div className="w-100" />
						<div className="col-sm mb-5">
							<div className="row">
								<div className="align-self-center text-center" style={{ 'min-width': 180 }}>
									<img height="150" src={cert4} alt="..." />
								</div>
								<div className="col-sm">
									<p className="mt-4">
										{i18n.certificates.p4}
									</p>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return ({
		i18n: state.lang
	})
}

export default connect(mapStateToProps)(Certified)
