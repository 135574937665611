import en from './en.json'
import es from './es.json'
import pt from './pt-br.json'

const langs = {
	'en': en,
	'es': es,
	'pt': pt,
}

export default function (lang = 'pt') {
	return langs[lang]
}
