import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import facebook from '../images/logo-facebook.svg'
import instagram from '../images/logo-instagram.svg'

class Footer extends Component {

	render() {
		const { i18n } = this.props

		return (
			<div style={{ width: '100%', background: '#f7971d', marginTop: 40 }}>
				<div className="container" style={{ paddingBottom: 20, paddingTop: 30 }}>
					<div className="row">
						<div className="col-sm">
							<p className="text-light d-none d-sm-block">
								Copyright © {new Date().getFullYear()} Caliman. {i18n.footer.copyright}
							</p>
							<p className="text-light text-center d-block d-sm-none">
								Copyright © {new Date().getFullYear()} Caliman. {i18n.footer.copyright}
							</p>
						</div>
						<div className="col-sm">
							<div className="d-none justify-content-end d-sm-flex">
								<a href="https://www.facebook.com/calimanagricola" style={{ paddingTop: 0 }} target="_blank" className="btn btn-link" rel="noopener noreferrer">
									<img src={facebook} height={24} alt="..." />
								</a>
								<a href="https://www.instagram.com/calimanagricola/" target="_blank" style={{ paddingTop: 0 }} className="btn btn-link" rel="noopener noreferrer">
									<img src={instagram} height={24} alt="..." />
								</a>
							</div>
							<div className="d-flex justify-content-center d-sm-none">
								<a href="https://www.facebook.com/calimanagricola" style={{ paddingTop: 0 }} target="_blank" className="btn btn-link" rel="noopener noreferrer">
									<img src={facebook} height={24} alt="..." />
								</a>
								<a href="https://www.instagram.com/calimanagricola/" target="_blank" style={{ paddingTop: 0 }} className="btn btn-link" rel="noopener noreferrer">
									<img src={instagram} height={24} alt="..." />
								</a>
							</div>
							{/* <div className="d-flex justify-content-center d-sm-none" style={{ paddingTop: 10, fontSize: 14 }}>
								<NavLink to={i18n.menu.linkposts || '/'} className={'top-link'}>
									{i18n.menu.itemposts}
								</NavLink>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return ({
		i18n: state.lang
	})
}

export default connect(mapStateToProps)(Footer)
