import React, { Component } from 'react'
import { connect } from 'react-redux'

import banner from '../images/sustentabilidade.jpg'
import image1 from '../images/s1.jpg'
import image2 from '../images/reflorestamento1.jpeg'
import image3 from '../images/r4.jpg'

class Sustainability extends Component {

	render() {
		const { i18n } = this.props

		return (
			<div>
				<div className="row" style={{ marginBottom: 40 }}>
					<div className="col-sm">
						<div className="position-absolute d-flex justify-content-center align-items-center flex-column"
							style={{ right: 0, left: 0, bottom: 0, top: 0, background: 'hsla(34, 94%, 54%, 0.5)' }}>
							<h1 className="text-center display-3 text-light d-none d-sm-block font-weight-bold ">
								{i18n.sus.banner_title}
							</h1>
							<h1 className="text-center text-light d-block d-sm-none font-weight-bold p-5">
								{i18n.sus.banner_title}
							</h1>
						</div>
						<div className="w-100 d-none d-sm-block" style={{ background: `url(${banner}) no-repeat center`, minHeight: 400, backgroundSize: 'cover' }} />
						<div className="w-100 d-block d-sm-none" style={{ background: `url(${banner}) no-repeat center`, minHeight: 200, backgroundSize: 'cover' }} />
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm">
							<p>
								{i18n.sus.p1}
								<br />
								{i18n.sus.p2}
							</p>
						</div>
					</div>
					<div className="row mb-5">
						<div className="col-sm">
							<img className="img-fluid" src={image1} alt="..." />
						</div>
						<div className="col-sm">
							<h5>
								{i18n.sus.title1}
							</h5>
							<p>
								{i18n.sus.p3}
							</p>
						</div>
					</div>
					<div className="row mb-5">
						<div className="col-sm">
							<img className="img-fluid" src={image2} alt="..." />
						</div>
						<div className="col-sm">
							<h5>
								{i18n.sus.title2}
							</h5>
							<p>
								{i18n.sus.p4}
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-sm">
							<img className="img-fluid" src={image3} alt="..." />
						</div>
						<div className="col-sm">
							<h5>
								{i18n.sus.title3}
							</h5>
							<p>
								{i18n.sus.p5}
							</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return ({
		i18n: state.lang
	})
}

export default connect(mapStateToProps)(Sustainability)
