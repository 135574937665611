import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import reducers from './ducks'

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['lang']
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(
	persistedReducer,
	applyMiddleware(thunk)
)
const persistor = persistStore(store)

export { store, persistor }
