import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from '../store'

import Header from './Header'
import Footer from './Footer'

import Home from './Home'
// import Posts from './Posts'
import About from './About'
import Product from './Product'
import Logistics from './Logistics'
import Certified from './Certified'
import Social from './Social'
import Sustainability from './Sustainability'
import Innovation from './Innovation'
import Contact from './Contact'

const App = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router>
				<div className="container-fluid" style={{ padding: 0, overflowX: 'hidden' }}>
					<Header />
					<div style={{ marginBottom: 100 }}>
						<Switch>
							<Route exact path='/' component={Home} />
							{/* <Route path='/publicacoes' component={Posts} /> */}
							<Route path='/sobre' component={About} />
							<Route path='/produtos' component={Product} />
							<Route path='/logistica' component={Logistics} />
							<Route path='/certificados' component={Certified} />
							<Route path='/social' component={Social} />
							<Route path='/sustentabilidade' component={Sustainability} />
							<Route path='/inovacao' component={Innovation} />
							<Route path='/contato' component={Contact} />

							<Route exact path='/en' component={Home} />
							{/* <Route path='/en/posts' component={Posts} /> */}
							<Route path='/en/about-us' component={About} />
							<Route path='/en/products' component={Product} />
							<Route path='/en/logistic' component={Logistics} />
							<Route path='/en/certificates/' component={Certified} />
							<Route path='/en/social' component={Social} />
							<Route path='/en/sustainability' component={Sustainability} />
							<Route path='/en/innovation' component={Innovation} />
							<Route path='/en/contact' component={Contact} />

							<Route exact path='/es' component={Home} />
							{/* <Route path='/es/publicaciones' component={Posts} /> */}
							<Route path='/es/sobre-nosotros' component={About} />
							<Route path='/es/productos' component={Product} />
							<Route path='/es/logistica' component={Logistics} />
							<Route path='/es/certificados' component={Certified} />
							<Route path='/es/social' component={Social} />
							<Route path='/es/sostenibilidad' component={Sustainability} />
							<Route path='/es/innovacion' component={Innovation} />
							<Route path='/es/contacto' component={Contact} />

							<Route component={Home} />
						</Switch>
					</div>
					<Footer />
				</div>
			</Router>
		</PersistGate>
	</Provider>
)

export default App
