const initialState = {
	lang: null,
	meta: {},
	menu: {},
	footer: {},
	home: {},
	about_us: {},
	products: {},
	logistics: {},
	certificates: {},
	social: {},
	sus: {},
	innovation: {},
	contact: {},
}

const Types = {
	LOAD_LANG: 'literals/LOAD_LANG'
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case Types.LOAD_LANG:
			return payload
		default:
			return state
	}
}

export const loadLang = lang => ({
	type: Types.LOAD_LANG,
	payload: lang,
})
