import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { loadLang } from '../store/ducks/lang'
import loadI18n from '../values/i18n'

import logo from '../images/logo_caliman.svg'

import br from '../images/00_br.png'
import en from '../images/00_en.png'
import es from '../images/00_es.png'

import facebook from '../images/logo-facebook.svg'
import instagram from '../images/logo-instagram.svg'

class Header extends Component {

	componentDidMount() {
		this.selectLang()
		if (window.location.pathname.includes('/en')) {
			this.setState({ lang: 'en' })
		} else if (window.location.pathname.includes('/es')) {
			this.setState({ lang: 'es' })
		} else if (this.props.i18n.lang) {
			this.setState({ lang: this.props.i18n.lang })
		} else {
			const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage
			const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]

			this.setState({ lang: languageWithoutRegionCode })
		}
	}

	componentDidUpdate(props, state) {
		if (state.lang !== this.state.lang)
			this.selectLang()
	}

	toogleCollapse = () => {
		this.setState({ collapsed: !this.state.collapsed })
	}

	state = {
		lang: 'pt',
		collapsed: true
	}

	selectLang = () => {
		const { loadLang } = this.props
		const i18n = loadI18n(this.state.lang)
		loadLang(i18n)
	}

	render() {
		const { i18n } = this.props
		const meta = {
			title: i18n.meta.title,
			description: i18n.meta.description,
			canonical: i18n.meta.url
		}

		return (
			<div>
				<DocumentMeta {...meta} />
				<div style={{ width: '100%', background: '#f7971d' }}>
					<div className="container d-flex justify-content-between">
						<div>
							<button type="button" className="btn btn-link" onClick={() => this.setState({ lang: 'pt' })}>
								<img src={br} height={18} alt="..." />
							</button>
							<button type="button" className="btn btn-link" onClick={() => this.setState({ lang: 'en' })}>
								<img src={en} height={18} alt="..." />
							</button>
							<button type="button" className="btn btn-link" onClick={() => this.setState({ lang: 'es' })}>
								<img src={es} height={18} alt="..." />
							</button>
						</div>
						<div>
							{/* <NavLink to={i18n.menu.linkposts || '/'} className={'btn btn-link top-link d-none d-sm-inline'}>
								{i18n.menu.itemposts}
							</NavLink> */}
							<a href="https://www.facebook.com/calimanagricola" className="btn btn-link" target="_blank" rel="noopener noreferrer">
								<img src={facebook} height={24} alt="..." />
							</a>
							<a href="https://www.instagram.com/calimanagricola/" className="btn btn-link" target="_blank" rel="noopener noreferrer">
								<img src={instagram} height={24} alt="..." />
							</a>
						</div>
					</div>
				</div>
				<nav className="navbar navbar-expand-lg navbar-light">
					<div className="container">
						<Link className="navbar-brand" to="/" onClick={() => this.setState({ collapsed: true })}>
							<img src={logo} width="120" height="auto" alt="..." data-target="#navtoggler" />
						</Link>
						<button className="navbar-toggler" type="button" data-target="#navtoggler" onClick={this.toogleCollapse}>
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className={(this.state.collapsed ? 'collapse' : '') + ' navbar-collapse'} id="navtoggler">
							<div className="navbar-nav ml-auto mt-2 mt-lg-0" data-target="#navtoggler">
								<NavLink to={i18n.menu.link1 || '/'} className={'nav-item nav-link ' + (window.location.pathname === i18n.menu.link1 ? 'selected' : '')} onClick={this.toogleCollapse}>
									{i18n.menu.item1}
								</NavLink>

								<NavLink to={i18n.menu.link2 || '/'} className={'nav-item nav-link ' + (window.location.pathname === i18n.menu.link2 ? 'selected' : '')} onClick={this.toogleCollapse}>
									{i18n.menu.item2}
								</NavLink>

								<NavLink to={i18n.menu.link3 || '/'} className={'nav-item nav-link ' + (window.location.pathname === i18n.menu.link3 ? 'selected' : '')} onClick={this.toogleCollapse}>
									{i18n.menu.item3}
								</NavLink>

								<NavLink to={i18n.menu.link4 || '/'} className={'nav-item nav-link ' + (window.location.pathname === i18n.menu.link4 ? 'selected' : '')} onClick={this.toogleCollapse}>
									{i18n.menu.item4}
								</NavLink>

								<NavLink to={i18n.menu.link5 || '/'} className={'nav-item nav-link ' + (window.location.pathname === i18n.menu.link5 ? 'selected' : '')} onClick={this.toogleCollapse}>
									{i18n.menu.item5}
								</NavLink>

								<NavLink to={i18n.menu.link6 || '/'} className={'nav-item nav-link ' + (window.location.pathname === i18n.menu.link6 ? 'selected' : '')} onClick={this.toogleCollapse}>
									{i18n.menu.item6}
								</NavLink>

								<NavLink to={i18n.menu.link7 || '/'} className={'nav-item nav-link ' + (window.location.pathname === i18n.menu.link7 ? 'selected' : '')} onClick={this.toogleCollapse}>
									{i18n.menu.item7}
								</NavLink>

								<NavLink to={i18n.menu.link8 || '/'} className={'nav-item nav-link ' + (window.location.pathname === i18n.menu.link8 ? 'selected' : '')} onClick={this.toogleCollapse}>
									{i18n.menu.item8}
								</NavLink>

								{/* <NavLink to={i18n.menu.linkposts || '/'} className={'nav-item nav-link d-sm-none'}>
									{i18n.menu.itemposts}
								</NavLink> */}
							</div>
						</div>
					</div>
				</nav>
			</div >
		)
	}
}

const mapStateToProps = state => {
	return ({
		i18n: state.lang
	})
}

const mapDispatchToProps = dispatch =>
	bindActionCreators({ loadLang }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Header)
