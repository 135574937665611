import React, { Component } from 'react'
import { connect } from 'react-redux'

import banner from '../images/banner_produtos.jpg'
import prod from '../images/Asset-6.png'
import prod1 from '../images/01-100.jpg'
import prod2 from '../images/02-100.jpg'
import prod3 from '../images/03-100.jpg'
import prod4 from '../images/04-100.jpg'
import prod5 from '../images/05-100.jpg'
import prod6 from '../images/06-100.jpg'

class Home extends Component {

	componentDidMount() {
		document.scrollingElement.scrollTop = 0
	}

	render() {
		const { i18n } = this.props

		return (
			<div>
				<div className="row" style={{ marginBottom: 40 }}>
					<div className="col-sm">
						<div className="position-absolute d-flex justify-content-center align-items-center flex-column"
							style={{ right: 0, left: 0, bottom: 0, top: 0, background: 'hsla(34, 94%, 54%, 0.5)' }}>
							<h1 className="text-center display-3 text-light d-none d-sm-block font-weight-bold ">
								{i18n.products.banner_title}
							</h1>
							<h1 className="text-center display-5 text-light d-block d-sm-none font-weight-bold">
								{i18n.products.banner_title}
							</h1>
						</div>
						<div className="w-100 d-none d-sm-block" style={{ background: `url(${banner}) no-repeat center`, minHeight: 400, backgroundSize: 'cover' }} />
						<div className="w-100 d-block d-sm-none" style={{ background: `url(${banner}) no-repeat center`, minHeight: 200, backgroundSize: 'cover' }} />
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm">
							<img className="d-block img-fluid" src={prod} alt="..." />
							<h5>
								{i18n.products.title2}
								<hr className="line" />
							</h5>
							<p>
								<b>{i18n.products.label1}</b> {i18n.products.item1}
							</p>
							<p>
								<b>{i18n.products.label2}</b> {i18n.products.item2}
							</p>
							<p>
								<b>{i18n.products.label3}</b> {i18n.products.item3}
							</p>
							<p>
								<b>{i18n.products.label4}</b> {i18n.products.item4}
							</p>
							<p>
								<b>{i18n.products.label5}</b> {i18n.products.item5}
							</p>
							<p>
								<b>{i18n.products.label6}</b> {i18n.products.item6}
							</p>
						</div>
						<div className="col-sm">
							<h5>
								{i18n.products.title1}
								<hr className="line" />
							</h5>
							<p>
								{i18n.products.p1}
							</p>
							<p>
								{i18n.products.p2}
							</p>
							<p>
								{i18n.products.p3}
							</p>
							<h6>
								{i18n.products.subtitle1}
							</h6>
							<p>
								{i18n.products.p4}<br />
								{i18n.products.p5}<br />
								{i18n.products.p6}<br />
								{i18n.products.p7}<br />
								{i18n.products.p8}<br />
							</p>
						</div>
						<div className="w-100" />
						<div className="col-sm">
							<h5>
								{i18n.products.title3}
								<hr className="line" />
							</h5>
							<div className="row mt-4">
								<div className="col-sm d-flex m-4">
									<img src={prod1} className="img-fluid d-none d-sm-block" alt="..." />
									<img src={prod1} height="100" className="d-block d-sm-none" alt="..." />
									<div className="ml-4">
										<b>Formosa</b><br />
										4,5 {i18n.products.kgl}<br />
										5,1 {i18n.products.kgg}
									</div>
								</div>
								<div className="col-sm d-flex m-4">
									<img src={prod2} className="img-fluid d-none d-sm-block" alt="..." />
									<img src={prod2} height="100" className="d-block d-sm-none" alt="..." />
									<div className="ml-4">
										<b>Golden 01</b><br />
										2,3 {i18n.products.kgl}<br />
										2,7 {i18n.products.kgg}
									</div>
								</div>
								<div className="w-100" />
								<div className="col-sm d-flex m-4">
									<img src={prod3} className="img-fluid d-none d-sm-block" alt="..." />
									<img src={prod3} height="100" className="d-block d-sm-none" alt="..." />
									<div className="ml-4">
										<b>Golden 02</b><br />
										3,5 {i18n.products.kgl}<br />
										4,0 {i18n.products.kgg}
									</div>
								</div>
								<div className="col-sm d-flex m-4">
									<img src={prod4} className="img-fluid d-none d-sm-block" alt="..." />
									<img src={prod4} height="100" className="d-block d-sm-none" alt="..." />
									<div className="ml-4">
										<b>Golden 03</b><br />
										4,5 {i18n.products.kgl}<br />
										5,3 {i18n.products.kgg}
									</div>
								</div>
								<div className="w-100" />
								<div className="col-sm d-flex m-4">
									<img src={prod5} className="img-fluid d-none d-sm-block" alt="..." />
									<img src={prod5} height="100" className="d-block d-sm-none" alt="..." />
									<div className="ml-4">
										<b>Golden 04</b><br />
										10,0 {i18n.products.kgl}<br />
										11,3 {i18n.products.kgg}
									</div>
								</div>
								<div className="col-sm d-flex m-4">
									<img src={prod6} className="img-fluid d-none d-sm-block" alt="..." />
									<img src={prod6} height="100" className="d-block d-sm-none" alt="..." />
									<div className="ml-4">
										<b>Clam shell</b><br />
										10,0 {i18n.products.kgl}<br />
										11,3 {i18n.products.kgg}<br />
										{i18n.products.info}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return ({
		i18n: state.lang
	})
}

export default connect(mapStateToProps)(Home)
